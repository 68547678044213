import { render, staticRenderFns } from "./PatrimonyCreateUpdate.vue?vue&type=template&id=5db74755&scoped=true&"
import script from "./PatrimonyCreateUpdate.vue?vue&type=script&lang=js&"
export * from "./PatrimonyCreateUpdate.vue?vue&type=script&lang=js&"
import style0 from "./PatrimonyCreateUpdate.vue?vue&type=style&index=0&id=5db74755&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db74755",
  null
  
)

export default component.exports