<template>
  <div>
    <b-tabs>
      <b-tab title="Dados">
        <b-row>
          <b-col sm="12">
            <div class="div-badge">
              <span v-if="type == 1">Equipamento</span>
              <span v-if="type == 2">Acessório</span>
              <span v-if="type == 3">Serviço</span>
              <span v-if="type == 5">Material</span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <Select title="Tipo" field="type" :formName="formName" :required="true"
              url="/api/v1/stock/type-product/select-all" :propsParams="{ any: type }" v-model="product.typeProduct"
              nameNewRegister="typeProductCreateUpdate" titleNewRegister="Tipo" :widthNewRegister="700"
              :heightNewRegister="250">
              <TypeProductCreateUpdate :registerInSelect="true" v-model="product.typeProduct" />
            </Select>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="100"
              v-model="product.name" />
          </b-col>
        </b-row>
        <b-row v-if="id">
          <b-col sm="12">
            <RadioGroup title="Status" field="status" :formName="formName" :options="[
              { text: 'Ativo', value: 1 },
              { text: 'Inativo', value: 2 },
            ]" v-model="product.status" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Foto">
        <ImageUpload title="Carregar Foto" container="nixloc-photo-product" :width="100" :height="100"
          accepted=".jpg .png" urlPost="/api/v1/adm/file-upload/upload" urlRemove="/api/v1/adm/file-upload/delete"
          :onLoad="updateImage" v-model="product.photo" />
      </b-tab>
    </b-tabs>
    <br />
    <b-tabs>
      <b-tab title="Principal" @click="currentTab('principal')">
        <b-row>
          <b-col sm="6">
            <InputText title="Código Interno" field="name" :formName="formName" :required="false" :maxLength="100"
              v-model="product.codInternal" />
          </b-col>
        </b-row>
        <b-row v-if="product.type != 3">
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <Select title="Categoria" field="categoryProduct" :formName="formName" :required="true"
              url="/api/v1/stock/category-product/select-all" v-model="product.categoryProduct"
              nameNewRegister="categoryProductCreateUpdate" titleNewRegister="Categoria" :widthNewRegister="500"
              :heightNewRegister="250">
              <CategoryProductCreateUpdate :registerInSelect="true" v-model="product.categoryProduct" />
            </Select>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <Select title="Fabricante" field="manufacturer" :formName="formName" :required="true"
              url="/api/v1/stock/manufacturer/select-all" v-model="product.manufacturer"
              nameNewRegister="manufacturerCreateUpdate" titleNewRegister="Fabricante" :widthNewRegister="500"
              :heightNewRegister="250">
              <ManufacturerCreateUpdate :registerInSelect="true" v-model="product.manufacturer" />
            </Select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <EditorHtml title="Descrição" field="description" :required="false" :maxLength="500"
              v-model="product.description" />
          </b-col>
        </b-row>
        <div v-if="isProduct || isAccessory">
          <b-row>
            <b-col sm="6">
              <InputText title="Código por Grupo" field="barCode" :formName="formName" :required="false"
                :maxLength="500" v-model="product.barCode" />
            </b-col>
            <b-col sm="2">
              <div class="div-btn-generate">
                <Button _key="btnGenerateCode" type="info" title="Gerar" classIcon="fal fa-barcode-read" size="small"
                  :clicked="generateCode" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="3" xl="3">
              <DateTime title="Data da Compra" field="purchaseDate" format="DD/MM/YYYY" type="date" placeholder
                :formName="formName" :required="false" v-model="product.purchaseDate" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="3" xl="3">
              <InputDecimal title="Compra/Reposição" field="number" :formName="formName" :required="false"
                v-model="product.replacementValue" />
            </b-col>
          </b-row>
        </div>
        <div v-if="isProduct || isMaterial || isAccessory">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
              <Select title="Unidade de Medida" field="unitOfMeasurement" :formName="formName" :required="true"
                url="/api/v1/stock/unit-of-measurement/select-all" v-model="product.unitOfMeasurement"
                nameNewRegister="unitOfMeasurementCreateUpdate" titleNewRegister="Unidade de Medida"
                :widthNewRegister="500" :heightNewRegister="250">
                <UnitOfMeasurementCreateUpdate :registerInSelect="true" v-model="product.unitOfMeasurement" />
              </Select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="2" xl="2">
              <InputNumber title="Estoque" field="totalStock" :formName="formName"
                :disabled="!product.allowWithoutPatrimony && manageByPatrimony && !isMaterial" :required="false"
                :maxLength="5" type="float" v-model="product.totalStock" />
            </b-col>
          </b-row>
        </div>

      </b-tab>
      <b-tab title="Patrimônio" @click="currentTab('patrimony')"
        v-if="!isService && !isMaterial && manageByPatrimony && id">
        <b-row>
          <b-col sm="12">
            <CheckboxSimple title="Não Patrimoniado" v-model="product.allowWithoutPatrimony" />
            <br />
            <PatrimonyList v-if="nameTab == 'patrimony' && !product.allowWithoutPatrimony" :productId="id" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Acessório" @click="currentTab('accessory')" v-if="isProduct && id">
        <AccessoryList v-if="nameTab == 'accessory'" :productId="id" />
      </b-tab>
      <b-tab title="Tabela de Preço" @click="currentTab('priceTable')" v-if="id">
        <PriceTableList v-if="nameTab == 'priceTable'" :productId="id" />
      </b-tab>
      <b-tab title="Seguro" @click="currentTab('insurance')" v-if="isProduct || isAccessory">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Porcentagem %" field="replacementValue" :formName="formName" :required="false"
              :maxLength="2" type="float" v-model="product.percentageInsurance" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <RadioGroup title="Como será realizado o cálculo ?" field="tipo" :formName="formName" :options="[
              { text: 'Valor da Locação', value: 1 },
              { text: 'Valor de Compra/Reposição', value: 2 },
            ]" v-model="product.calculateInsurance" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Fiscal" @click="currentTab('taxDocument')" v-if="isProduct || isAccessory">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="3" xl="3">
            <InputText title="Código Benefício Fiscal" field="codigoBeneficioFiscal" :formName="formName"
              :required="false" :maxLength="50" type="float" v-model="product.codigoBeneficioFiscal" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputText title="Ncm" field="ncm" :formName="formName" :required="false" :maxLength="8" type="float"
              v-model="product.ncm" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Anexo">
        <FileManager :showInModal="true" source="product" :genericId="id" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import ImageUpload from "@nixweb/nixloc-ui/src/component/forms/ImageUpload";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import TypeProductCreateUpdate from "../type-product/TypeProductCreateUpdate.vue";
import CategoryProductCreateUpdate from "../category-product/CategoryProductCreateUpdate.vue";
import ManufacturerCreateUpdate from "../manufacturer/ManufacturerCreateUpdate.vue";
import UnitOfMeasurementCreateUpdate from "../unit-of-measurement/UnitOfMeasurementCreateUpdate.vue";
import PatrimonyList from "../patrimony/PatrimonyList.vue";
import AccessoryList from "../accessory/AccessoryList.vue";
import PriceTableList from "../price-table/PriceTableList.vue";

import Product from "@/components/modules/stock/product/Product.js";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ProductCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    ImageUpload,
    InputText,
    InputDecimal,
    InputNumber,
    CheckboxSimple,
    Select,
    RadioGroup,
    EditorHtml,
    CheckboxGroup,
    Molded,
    Button,
    DateTime,
    TypeProductCreateUpdate,
    CategoryProductCreateUpdate,
    ManufacturerCreateUpdate,
    UnitOfMeasurementCreateUpdate,
    PatrimonyList,
    AccessoryList,
    PriceTableList,
    FileManager,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      formName: "productCreateUpdate",
      product: new Product(),
      urlCreate: "/api/v1/stock/product/create",
      urlUpdate: "/api/v1/stock/product/update",
      urlGetById: "/api/v1/stock/product/get-by-id",
      nameTab: "",
    };
  },
  created() {
    if (this.id) this.getById();
    this.product.type = this.type;
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.product };
      this.postApi(params).then((response) => {
        if (response.success && this.registerInSelect) {
          this.hideVodal(this.formName);
        } else if (response.success) {
          this.$router.replace({
            name: "productUpdate",
            params: { id: response.content.id, type: response.content.type },
          });
          this.removeLoading(["saveSaveCancel"]);
        }
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.product };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    updateImage() {
      if (this.id) this.update();
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.product.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeFormDirty();
          self.removeLoading(["panel"]);
        }, 100);
      });
    },
    currentTab(name) {
      this.nameTab = name;
      if (name == "principal") {
        if (this.id) this.getById();
      }
    },
    generateCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < 20; i++) {
        const index = Math.floor(Math.random() * characters.length);
        result += characters[index];
      }

      let self = this;
      setTimeout(function () {
        self.product.barCode = result;
        self.removeLoading(["btnGenerateCode"]);
      }, 500);

    }
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    isProduct() {
      if (this.product.type == 1) return true;
      return false;
    },
    isAccessory() {
      if (this.product.type == 2) return true;
      return false;
    },
    isMaterial() {
      if (this.product.type == 5) return true;
      return false;
    },
    isService() {
      if (this.product.type == 3) return true;
      return false;
    },
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
    tabIndex: {
      handler(tabIndex) {
        if (tabIndex == 0) this.getById();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-badge {
  margin-bottom: 10px;
}

.div-btn-generate {
  margin-top: 38px;
}
</style>
