<template>
  <div>
    <b-row v-if="!patrimony.id">
      <b-col sm="12">
        <div class="div-optical">
          <CheckboxSimple title="Leitor Óptico" v-model="opticalReader" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col sm="10">
            <InputText title="Código" field="barCode" :formName="formName" :required="true" :maxLength="500"
              :enter="createWithOpticalReader" v-model="patrimony.barCode" :markFormDirty="false" />
          </b-col>
          <b-col sm="2" v-if="!opticalReader">
            <div class="div-btn-generate">
              <Button _key="btnGenerateCode" type="info" title="Gerar" classIcon="fal fa-barcode-read" size="small"
                :clicked="generateCode" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-show="opticalReader">
      <b-col sm="12">
        <span class="title-barcode">
          <i class="fal fa-barcode-read"></i> Digite o Nº do Patrimônio e pressione enter
          ou utilize o Leitor Óptico</span>
      </b-col>
    </b-row>
    <div v-show="!opticalReader">
      <b-row>
        <b-col sm="8">
          <InputText title="Nº Serial" field="serialNumber" :formName="formName" :required="false" :maxLength="200"
            v-model="patrimony.serialNumber" :markFormDirty="false" />
        </b-col>
        <b-col sm="4">
          <DateTime title="Data da Compra" field="deliveryHour" format="DD/MM/YYYY" type="date" placeholder
            :formName="formName" :required="false" v-model="patrimony.purchaseDate" />
        </b-col>
      </b-row>
      <b-row v-if="patrimony.statusName == 'Disponível' || patrimony.statusName == 'Inativo'">
        <b-col sm="12">
          <RadioGroup title="Status" field="status" :formName="formName" :options="[
            { text: 'Ativo', value: 1 },
            { text: 'Inativo', value: 4 },
          ]" v-model="patrimony.status" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="text-right">
            <Button _key="btnSavePatrimony" type="success" title="Salvar" :disabled="!isFormValid(formName)"
              classIcon="fas fa-save" size="medium" :clicked="create" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import Patrimony from "@/components/modules/stock/patrimony/Patrimony.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PatrimonyCreateUpdate",
  props: {
    productId: String,
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, RadioGroup, DateTime, Button, CheckboxSimple },
  data() {
    return {
      formName: "patrimonyCreateUpdate",
      patrimony: new Patrimony(),
      urlCreate: "/api/v1/stock/patrimony/create",
      urlUpdate: "/api/v1/stock/patrimony/update",
      opticalReader: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      this.patrimony.productId = this.productId;
      if (this.patrimony.id) {
        let params = { url: this.urlUpdate, obj: this.patrimony };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSavePatrimony"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.patrimony };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect && !this.opticalReader)
            this.hideModal();

          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.data.id,
              content: response.data.name,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSavePatrimony"]);
        });
      }
    },
    update(data) {
      this.opticalReader = false;
      this.patrimony = new Patrimony();
      this.patrimony.update(data);
      this.removeLoading([data.id]);
    },
    createWithOpticalReader() {
      if (this.opticalReader) {
        this.create();
        this.patrimony.barCode = "";
        this.resetValidation(this.formName);
      }
    },
    generateCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < 20; i++) {
        const index = Math.floor(Math.random() * characters.length);
        result += characters[index];
      }

      let self = this;
      setTimeout(function () {
        self.patrimony.barCode = result;
        self.removeLoading(["btnGenerateCode"]);
      }, 500);

    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "patrimonyCreate") {
          this.opticalReader = false;
          this.resetValidation(this.formName);
          this.patrimony = new Patrimony();
        }
        if (event.name == "updatePatrimony") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-optical {
  margin-bottom: 10px;
}

.title-barcode {
  color: #7d8083;
}

.div-btn-generate {
  margin-top: 38px;
}
</style>
